import { useLazyQuery, useMutation } from '@apollo/client'

import * as QUERIES from './queries'
import * as MUTATIONS from './mutations'
import { useGetCompanies } from '../Cms/hooks'

export function useFindEquipments() {
  const [findEquip, { loading, error, data }] = useLazyQuery(
    QUERIES.FIND_EQUIPMENTS
  )

  const findEquipments = filter => findEquip({ variables: { filter } })

  return [findEquipments, { loading, error, data }]
}

export function useCloneEquipments() {
  const [cloneEquip, { loading, error, data }] = useMutation(
    MUTATIONS.CLONE_EQUIPMENTS
  )

  const cloneEquipments = payload => cloneEquip({ variables: { payload } })

  return [cloneEquipments, { loading, error, data }]
}

export const useGetOrgranisations = useGetCompanies
