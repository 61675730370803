import { useQuery, useMutation } from '@apollo/client'

import * as QUERIES from './queries'
import * as MUTATIONS from './mutations'
import { useGetUsers } from '../Cms/hooks'

export function useGetCompanyAdmins() {
  const { loading, error, data, refetch } = useQuery(QUERIES.GET_COMPANY_ADMINS)

  return { loading, error, data, refetch }
}

// export function useGetProjectManagers(company) {
//   const { loading, error, data } = useQuery(QUERIES.GET_PROJECT_MANAGERS, {
//     variables: { company },
//   })
//
//   return { loading, error, data }
// }

export function useRegisterProjectManager() {
  const [registerProjectManager, { loading, error, data }] = useMutation(
    MUTATIONS.REGISTER_PROJECT_MANAGER,
    { errorPolicy: 'all' }
  )

  const saveProjectManager = manager =>
    registerProjectManager({ variables: { manager } })

  return [saveProjectManager, { loading, error, data }]
}

export const useGetProjectManagers = useGetUsers
