import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Row, Col } from 'react-bootstrap'
import Select from 'react-select'

import { userSchema } from './validations'
import { DROPDOWN_STYLES } from '../../constants/styles'
import { ROLES } from './constants'

export function UserEditForm(props) {
  const { values, formRef, onUserSubmit } = props

  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: values || {},
    resolver: yupResolver(userSchema),
  })

  const onSubmit = data => {
    const { firstName, lastName, ...rest } = data
    onUserSubmit({
      firstName: firstName.trim(),
      lastName: lastName?.trim(),
      ...rest,
    })
  }

  return (
    <Form ref={formRef} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={6}>
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              size="lg"
              {...register('firstName')}
              isInvalid={!!errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              size="lg"
              {...register('lastName')}
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          size="lg"
          {...register('email')}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="active" className="d-flex mb-0">
        <Form.Check
          type="checkbox"
          {...register('active')}
          isInvalid={!!errors.active}
        />
        <Form.Label>Active</Form.Label>
      </Form.Group>
      <Form.Control.Feedback type="error" className="mb-4">
        {errors.active?.message}
      </Form.Control.Feedback>
      <Form.Group controlId="role">
        <Form.Label>Role</Form.Label>
        <Controller
          render={({ field: { onChange } }) => (
            <Select
              styles={DROPDOWN_STYLES}
              options={ROLES}
              defaultValue={ROLES.find(option => option.value === values.role)}
              onChange={({ value }) => onChange(value)}
            />
          )}
          name="role"
          control={control}
        />
        <Form.Control.Feedback type="error">
          {errors.role?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  )
}

export default UserEditForm
