import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert } from 'react-bootstrap'

import { BasicCard, Title, Text } from '../Themed'
import { SignInForm } from './forms'
import withSession from '../Session'
import { useSignIn } from './hooks'

function NotVerifiedError() {
  return <Alert variant="danger">Email not verified. </Alert>
}

function NotActiveError() {
  return (
    <Alert variant="danger">
      Account not activated. Please contact{' '}
      <Alert.Link href="#">HST Support</Alert.Link>.
    </Alert>
  )
}

function EmailDoesNotExistError() {
  return <Alert variant="danger">Admin does not exist.</Alert>
}

function IncorrectPasswordError() {
  return <Alert variant="danger">Incorrect Password! Please try again.</Alert>
}

const ERRORS = {
  INVALID_USER: <EmailDoesNotExistError />,
  INVALID_PASSWORD: <IncorrectPasswordError />,
  NOT_VERIFIED: <NotVerifiedError />,
  INACTIVE: <NotActiveError />,
}

function SignIn(props) {
  const [networkError, setNetworkError] = useState(undefined)
  const history = useHistory()

  const [signIn, { loading }] = useSignIn(
    error => setNetworkError(error),
    async data => {
      localStorage.setItem('token', data.token.accessToken)
      localStorage.setItem('refreshToken', data.token.refreshToken)
      await props?.refetch()
      history.push('/')
    }
  )

  return (
    <BasicCard
      padding={32}
      className="mx-auto m-4"
      style={{ maxWidth: '480px' }}
    >
      <div className="text-center mb-4">
        <img src="/logo512.png" style={{ height: 84 }} />
        <Text className="d-block">
          from <b>HST</b>
        </Text>
      </div>
      <Title className="text-center mb-4">ADMIN PANEL</Title>
      {networkError &&
        networkError.message &&
        ERRORS[`${networkError.message}`]}
      <SignInForm
        onSignIn={({ email, password }) => signIn({ email, password })}
        loading={loading}
        networkError={networkError}
      />
    </BasicCard>
  )
}

export default withSession(SignIn)
