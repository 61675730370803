import { useEffect, useState, useRef } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { useUpdateEmailDomain } from './hooks'
import { DomainForm } from './forms'

function UpdateDomain(props) {
  const { domain } = props
  const [updateDomain, { loading, data }] = useUpdateEmailDomain()
  const formRef = useRef(null)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = (
    () => () =>
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
  )(formRef)

  //Felt cute. Might delete later.
  const handleDomainUpdate = (
    (domainId, updateDomainFn) => domainData =>
      updateDomainFn(domainId, domainData)
  )(domain.id, updateDomain)

  useEffect(() => {
    if (data?.updateDomain?.id) handleClose()
  }, [data])

  return (
    <>
      <span
        role="button"
        className="material-icons icon-primary icon-16 ml-2"
        onClick={handleShow}
      >
        edit
      </span>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header>
          <Modal.Title>Edit Email Domain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DomainForm
            formRef={formRef}
            onFormSubmit={handleDomainUpdate}
            values={domain}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
            className="d-flex align-items-center justify-content-center"
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
              />
            )}
            <span>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateDomain
