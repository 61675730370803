import { useState, useRef, useContext } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { SiteEdit } from '../forms'
import { gql, useMutation } from '@apollo/client'
import { MapContext } from '../../../context'

const CREATE_SITE_ADMIN = gql`
  mutation CREATE_SITE_ADMIN($site: PreQualifiedSiteInput!) {
    createSiteAdmin(site: $site) {
      id
    }
  }
`

const TECHNOLOGY_OPTIONS = [
  { label: 'Wind', value: 'WIND' },
  { label: 'Bess', value: 'BESS' },
  { label: 'Hydrogen', value: 'HYDROGEN' },
]

const { REACT_APP_DEFAULT_BLACKLIST } = process.env

function CreateSite() {
  const [show, setShow] = useState(false)
  const formRef = useRef(null)
  const { refetch } = useContext(MapContext)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [createSiteAdmin, { loading }] = useMutation(CREATE_SITE_ADMIN, {
    onCompleted: () => {
      handleClose()
      refetch()
    },
  })

  const handleSubmit = (
    () => () =>
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
  )(formRef)

  const handleSiteCreate = data => {
    createSiteAdmin({ variables: { site: data } })
  }

  return (
    <>
      <Button onClick={handleShow} variant="outline-primary" size="sm">
        + Add Site
      </Button>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header>
          <Modal.Title className="w-100">
            <div className="d-flex justify-content-between">
              <div>
                <span>Site Information</span>&nbsp;
              </div>
            </div>{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SiteEdit
            formRef={formRef}
            onFormSubmit={handleSiteCreate}
            siteTechOpts={TECHNOLOGY_OPTIONS}
            values={{
              companyBlacklist: (REACT_APP_DEFAULT_BLACKLIST || '').split(' '),
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
            className="d-flex align-items-center justify-content-center"
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
              />
            )}
            <span>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateSite
