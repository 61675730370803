import React, { useState, useEffect, useCallback, useContext } from 'react'
import ReactDOM from 'react-dom'
import { loadModules } from 'esri-loader'
import { useUpdateParcel } from './hooks'
import { ParcelEdit } from './forms'
import { GRAPHIC_PROPERTIES, FEATURE_LAYER_PROPERTIES } from './constants'
import ParcelList, { ParcelInfo } from './List'
import { MapContext } from './context'

const DETAILED_POLYGON_LEVEL = 10

const listToMatrix = (list, elementsPerSubArray = 2) => {
  var matrix = [],
    i,
    k

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++
      matrix[k] = []
    }

    matrix[k].push(list[i])
  }

  return matrix
}

function Sites() {
  const {
    mapView: { map, view },
    selectedParcel: [clickedParcel],
    site: { parcels },
  } = useContext(MapContext)
  const [graphicsLayer, setGraphicsLayer] = useState(undefined)
  const [featureLayer, setFeatureLayer] = useState(undefined)
  const [renderClusters, setRenderClusters] = useState(true)

  const [updateParcel, { data }] = useUpdateParcel()

  useEffect(() => {
    if (data?.updateParcel?.success) {
      view.popup.close()
    }
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    loadModules(['esri/layers/GraphicsLayer']).then(([GraphicsLayer]) => {
      if (!graphicsLayer) {
        setGraphicsLayer(new GraphicsLayer({ id: 'sitesLayer' }))
      }
    })
  }, [view, graphicsLayer])

  useEffect(() => {
    loadModules(['esri/layers/FeatureLayer']).then(([FeatureLayer]) => {
      if (!featureLayer) {
        const newFeatureLayer = new FeatureLayer(FEATURE_LAYER_PROPERTIES)
        setFeatureLayer(newFeatureLayer)
        map.add(newFeatureLayer)
      }
    })
    // eslint-disable-next-line
  }, [featureLayer])

  useEffect(() => {
    if (view) {
      loadModules(['esri/core/reactiveUtils']).then(([reactiveUtils]) => {
        reactiveUtils.watch(
          () => view.zoom,
          zoom => {
            if (Number.isInteger(zoom)) {
              setRenderClusters(zoom < DETAILED_POLYGON_LEVEL)
            }
          }
        )
      })
    }
  }, [view])

  useEffect(() => {
    if (featureLayer && graphicsLayer) {
      featureLayer.visible = renderClusters
      graphicsLayer.visible = !renderClusters
    }
  }, [featureLayer, graphicsLayer, renderClusters])

  useEffect(() => {
    if (clickedParcel && graphicsLayer) {
      let selectedGraphic = graphicsLayer.graphics.items.find(
        graphic => graphic.id === clickedParcel.id
      )

      selectedGraphic.popupTemplate = {
        content: function () {
          let popupNode = document.createElement('div')
          ReactDOM.render(
            <ParcelEdit
              parcel={selectedGraphic.attributes}
              onFormSubmit={data => {
                const { createdAt, updatedAt, ...restParcel } = clickedParcel
                console.log({ createdAt, updatedAt })
                let updatedParcel = {
                  ...restParcel,
                  number: data.parcelNumber,
                  iso: data.iso,
                  isEnergyCommunityZone: data.isEnergyCommunityZone,
                  leaseRate: Number(data.leaseRate),
                  leaseEsc: Number(data.leaseEsc),
                  offtakerDemandInMWh: Number(data.offtakerDemandInMWh),
                  nOfftakers: Number(data.nOfftakers),
                  acreage: Number(data.parcelAcreage),
                  owner: {
                    ...clickedParcel.owner,
                    name: data.ownerName,
                    phone: data.ownerPhone,
                    email: data.ownerEmail,
                  },
                }

                updateParcel(updatedParcel)
              }}
            />,
            popupNode
          )
          return popupNode
        },
      }
      view.popup.open({
        location: clickedParcel.centroid,
        features: [selectedGraphic],
      })
    }
    // eslint-disable-next-line
  }, [graphicsLayer, clickedParcel])

  useEffect(() => {
    if (graphicsLayer && parcels) {
      loadModules(['esri/Graphic', 'esri/geometry/Polygon']).then(
        ([Graphic, Polygon]) => {
          graphicsLayer.removeAll()
          parcels.forEach(parcel => {
            const polygon = new Polygon({
              hasZ: true,
              hasM: true,
              rings: parcel.boundaries.map(boundary =>
                listToMatrix(boundary.path)
              ),
              spatialReference: { wkid: 4326 },
            })
            const graphic = new Graphic({
              ...GRAPHIC_PROPERTIES,
              id: parcel.id,
              geometry: polygon,
              attributes: {
                ...parcel,
                ownerName: parcel.owner?.name,
                ownerPhone: parcel.owner?.phone,
                ownerEmail: parcel.owner?.email,
                parcelAcreage: parcel.acreage,
                parcelNumber: parcel.number,
                id: parcel.id,
              },
              popupTemplate: {
                content: function () {
                  let popupNode = document.createElement('div')
                  ReactDOM.render(
                    <ParcelEdit
                      parcel={graphic.attributes}
                      onFormSubmit={data => {
                        const { createdAt, updatedAt, ...restParcel } = parcel
                        console.log({ createdAt, updatedAt })
                        let updatedParcel = {
                          ...restParcel,
                          number: data.parcelNumber,
                          acreage: Number(data.parcelAcreage),
                          owner: {
                            ...parcel.owner,
                            name: data.ownerName,
                            phone: data.ownerPhone,
                            email: data.ownerEmail,
                          },
                        }

                        updateParcel(updatedParcel)
                      }}
                    />,
                    popupNode
                  )
                  return popupNode
                },
              },
            })
            graphicsLayer.add(graphic)
          })
          map.add(graphicsLayer)
        }
      )
    }
    // eslint-disable-next-line
  }, [graphicsLayer, parcels])

  useEffect(() => {
    if (featureLayer && parcels) {
      loadModules(['esri/Graphic', 'esri/geometry/Point']).then(
        ([Graphic, Point]) => {
          featureLayer.queryFeatures().then(results => {
            const deleteEdits = {
              deleteFeatures: results.features,
            }
            featureLayer.applyEdits(deleteEdits)
          })
          const points = parcels.map(parcel => {
            const point = new Point({
              longitude: parcel.centroid[0],
              latitude: parcel.centroid[1],
            })
            const markerSymbol = {
              type: 'simple-marker',
              color: [226, 119, 40],
            }
            return new Graphic({
              geometry: point,
              Symbol: markerSymbol,
            })
          })

          featureLayer.applyEdits({
            addFeatures: points,
          })
        }
      )
    }
  }, [featureLayer, parcels])

  return <div></div>
}

function PreQualifiedSitesMap(props) {
  const { parcels, sites, refetch } = props
  const [map, setMap] = useState(undefined)
  const [view, setView] = useState(undefined)
  const [clickedParcel, setClickedParcel] = useState(undefined)

  useEffect(() => {
    loadModules(['esri/Map', 'esri/views/MapView']).then(([Map, MapView]) => {
      const newMap = new Map({
        basemap: 'hybrid',
      })

      const newView = new MapView({
        container: 'viewDiv',
        map: newMap,
        zoom: 5,
        center: [-98.35, 39.5],
      })

      setMap(newMap)
      setView(newView)
    })
  }, [])

  const goToDefaultView = useCallback(() => {
    if (view) {
      view.popup.close()
      view.goTo({
        zoom: 5,
        center: [-98.35, 39.5],
      })
    }
  }, [view])

  const handleParcelItemClick = (mapView => {
    return parcel => {
      mapView.goTo({
        center: parcel.centroid,
        zoom: 15,
      })
      setClickedParcel(parcel)
    }
  })(view)

  const handleReset = () => {
    goToDefaultView()
    setClickedParcel(undefined)
  }

  return (
    <MapContext.Provider
      value={{
        mapView: { map, view },
        site: { sites, parcels },
        refetch,
        selectedParcel: [clickedParcel, setClickedParcel],
        handleParcelItemClick,
        handleReset,
      }}
    >
      <div className="position-relative h-100 w-100">
        {!clickedParcel ? <ParcelList /> : <ParcelInfo />}
        <div id="viewDiv" className="h-100 w-100">
          {view && map && <Sites />}
        </div>
      </div>
    </MapContext.Provider>
  )
}

export default PreQualifiedSitesMap
