import React, { useState, useEffect } from 'react'
import { Spinner, Row, Col, Button } from 'react-bootstrap'
import Select from 'react-select'
import { useApolloClient } from '@apollo/client'
import { AsyncPaginate } from 'react-select-async-paginate'

import Loader from '../Loader'
import ToastNotification from '../Notification'
import { Card, Text, Title } from '../Themed'
import { withAuthorization } from '../Session'
import { DROPDOWN_STYLES } from '../../constants/styles'

import * as hooks from './hooks'
import { GET_PROJECTS } from '../Cms/queries'

const CLONE_ERR_MSGS = {
  NO_ADMIN:
    'This project is not assigned to anyone. Assign this project by logging in as admin into the user account on View 2.0 for this project for this project to be clonable.',
  NO_STATION:
    'Weather station not assigned to this project. Login into this user account and assign a weather station to this project for it to be clonable',
  DEFAULT: 'Something went wrong! Please try again.',
}

function ProjectSelect(props) {
  const { company, destination } = props
  const [showErr, toggleErr] = useState(false)
  const [selectedProject, setSelectedProject] = useState(undefined)
  const [show, toggleToast] = useState(false)
  // const filter = { company }
  // const { loading, error, data, fetchMore } = hooks.useFindProjects(filter)
  const [
    cloneProject,
    { loading: cloning, data: cloneResponse, error: cloneErr },
  ] = hooks.useCloneProject()

  const client = useApolloClient()
  const handleLoadMore = async (search, prevOptions) => {
    const { data } = await client.query({
      query: GET_PROJECTS,
      variables: {
        filter: {
          company,
          limit: 50,
          offset: prevOptions.length,
          search,
        },
      },
    })

    const projectOptions = data?.projects.map(project => {
      return {
        value: project.id,
        label: project.name,
      }
    })

    return {
      options: projectOptions,
      hasMore: !!projectOptions.length,
    }
  }

  // useEffect(() => {
  //   if (company) getProjects(company)
  // }, [company])

  useEffect(() => {
    if (cloneResponse?.cloneProjectAdmin?.success) toggleToast(true)
  }, [cloneResponse])

  useEffect(() => {
    if (cloneErr) toggleErr(true)
  }, [cloneErr])

  // if (loading) return <p>loading projects...</p>
  // if (error) return <p>error...</p>
  if (!company) return <p>Select organisation...</p>

  return (
    <>
      <ToastNotification
        show={showErr}
        onSuspend={() => toggleErr(false)}
        headerText="ERROR!"
        height={CLONE_ERR_MSGS[cloneErr?.message] && 180}
        bodyText={CLONE_ERR_MSGS[cloneErr?.message] || CLONE_ERR_MSGS.DEFAULT}
        type="danger"
      />
      <ToastNotification
        show={show}
        onSuspend={() => toggleToast(false)}
        headerText="Project cloned Successfully!"
        bodyText="Your changes have been saved successfully."
        type="primary"
      />
      <div className="mb-4">
        <Text>PROJECT</Text>
        <AsyncPaginate
          style={DROPDOWN_STYLES}
          className="w-100"
          debounceTimeout={300}
          loadOptions={handleLoadMore}
          placeholder="Search Projects..."
          onChange={({ value }) => setSelectedProject(value)}
          cacheUniqs={[company]}
          styles={{ ...DROPDOWN_STYLES }}
        />
      </div>
      <Button
        variant="primary"
        disabled={!(destination && selectedProject) || cloning}
        onClick={() => cloneProject({ project: selectedProject, destination })}
      >
        <div className="d-flex align-items-center justify-content-center">
          {cloning && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
          )}
          <span>{cloning ? 'Cloning' : 'Clone'}</span>
        </div>
      </Button>
    </>
  )
}

function Projects() {
  const [organisation, setOrganisation] = useState(undefined)
  const [destination, setDestination] = useState(undefined)
  const { loading, error, data } = hooks.useGetOrganisations()

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { companies } = data

  return (
    <Card padding={32}>
      <Row>
        <Col xs={6}>
          <Title>CLONE PROJECT FROM:</Title>
          <div className="mb-4">
            <Text>ORGANISATION</Text>
            <Select
              className="mr-2 mt-2"
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={DROPDOWN_STYLES}
              options={companies.map(company => ({
                label: company.name,
                value: company.id,
              }))}
              onChange={({ value }) => setOrganisation(value)}
            />
          </div>
          <ProjectSelect company={organisation} destination={destination} />
        </Col>
        <Col xs={6}>
          <Title>CLONE PROJECT TO:</Title>
          <div className="mb-4">
            <Text>ORGANISATION</Text>
            <Select
              className="mr-2 mt-2"
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={DROPDOWN_STYLES}
              options={companies.map(company => ({
                label: company.name,
                value: company.id,
              }))}
              onChange={({ value }) => setDestination(value)}
            />
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default withAuthorization(session => session && session.me)(Projects)
