import * as ROUTES from '../../constants/routes'

export const NAV_ITEMS = [
  {
    icon: 'area_chart',
    tab: 'analytics',
    label: 'Analytics',
    route: ROUTES.LANDING,
  },
  {
    icon: 'satellite',
    tab: 'site_upload',
    label: 'Parcels',
    route: ROUTES.SITE_UPLOAD,
  },
  {
    icon: 'forum',
    tab: 'conversations',
    label: 'Conversations',
    route: ROUTES.CONVERSATIONS,
  },
  {
    icon: 'location_on',
    tab: 'originations',
    label: 'Originations Map',
    route: ROUTES.ORIGINATIONS,
  },
  {
    icon: 'dataset_linked',
    tab: 'marketDataServices',
    label: 'Market Data Services',
    route: ROUTES.MARKET_DATA_SERVICES,
  },

  {
    icon: 'shield',
    tab: 'cms',
    label: 'CMS',
    route: ROUTES.CMS,
  },
  {
    icon: 'manage_accounts',
    tab: 'managers',
    label: 'Project Managers',
    route: ROUTES.MANAGERS,
  },
  {
    icon: 'hardware',
    tab: 'equipment',
    label: 'Clone Equipments',
    route: ROUTES.EQUIPMENTS,
  },
  {
    icon: 'flash_on',
    tab: 'projects',
    label: 'Clone Projects',
    route: ROUTES.PROJECTS,
  },
  {
    icon: 'analytics',
    tab: 'updateAnalytics',
    label: 'Data Analytics Map source',
    route: ROUTES.ANALYTICS_SOURCE,
  },
  {
    icon: 'business',
    tab: 'companies',
    label: 'Update Company',
    route: ROUTES.COMPANIES,
  },
  {
    icon: 'trending_up',
    tab: 'usage',
    label: 'Usage Stats',
    route: ROUTES.USAGE,
  },
  {
    icon: 'person_add',
    tab: 'signup',
    label: 'Add New User',
    route: ROUTES.SIGN_UP,
  },
  {
    icon: 'email',
    tab: 'emailDomains',
    label: 'Email Domains',
    route: ROUTES.EMAIL_DOMAINS,
  },
]
