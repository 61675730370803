import React, { useEffect } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { dataSourceSchema } from './validations'

export function DataSourceForm(props) {
  const { values, loading, onSubmit } = props

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: values || {},
    resolver: yupResolver(dataSourceSchema),
  })

  const { errors } = formState

  useEffect(() => {
    reset(values)
  }, [values])

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="totalCorporateDemand">
        <Form.Label>Total Corporate Demand (MWs)</Form.Label>
        <Form.Control
          type="number"
          size="lg"
          {...register('totalCorporateDemand')}
          isInvalid={!!errors.totalCorporateDemand}
        />
        <Form.Control.Feedback type="invalid">
          {errors.totalCorporateDemand?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="medianPPAPrice">
        <Form.Label>Median PPA Price ($/MWh)</Form.Label>
        <Form.Control
          type="number"
          size="lg"
          {...register('medianPPAPrice')}
          isInvalid={!!errors.medianPPAPrice}
        />
        <Form.Control.Feedback type="invalid">
          {errors.medianPPAPrice?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="medianPPATerm">
        <Form.Label>Median PPA Term (Years)</Form.Label>
        <Form.Control
          type="number"
          size="lg"
          {...register('medianPPATerm')}
          isInvalid={!!errors.medianPPATerm}
        />
        <Form.Control.Feedback type="invalid">
          {errors.medianPPATerm?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Button size="lg" disabled={loading} variant="primary" type="submit">
        <div className="d-flex align-items-center justify-content-center">
          {loading && (
            <Spinner
              className="mr-2"
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <span>Save</span>
        </div>
      </Button>
    </Form>
  )
}
