import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { withAuthorization } from '../Session'
import { Card, CardContent, Title } from '../Themed'
import ToastNotification from '../Notification'
import { CMS } from '../../constants/routes'

import { SignupForm } from './forms'
import { useAddUser } from './hooks'

function Signup() {
  const [showToast, setShowToast] = useState(false)

  const [addUser, { loading, error, data }] = useAddUser()
  const history = useHistory()

  useEffect(() => {
    if (data?.response?.success) setShowToast(true)
  }, [data])

  return (
    <>
      <ToastNotification
        show={showToast}
        onSuspend={() => history.push(CMS)}
        headerText="SUCCESS"
        bodyText="The user has been added successfully!"
      />
      <Card padding={32}>
        <CardContent>
          <Title>ADD NEW USER</Title>
          <SignupForm
            onSignUp={data => addUser(data)}
            loading={loading}
            error={error?.message}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default withAuthorization(session => session && session.me)(Signup)
