import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  Card as BootstrapCard,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import { Card, Text } from '../Themed'
import { gql, useQuery } from '@apollo/client'
import styled, { css } from 'styled-components'
import { NEUTRAL_2, PRIMARY_1, PRIMARY_2 } from '../../constants/colors'
import Loader from '../Loader'
import CreateConversation from './CreateConversation'
import { CONVERSATION_FIELDS } from './CreateConversation'
import ConversationFilter from './ConversationFilter'
import CreateDraft from './CreateDraft'

const { REACT_APP_INBOUND_EMAIL_SUBDOMAIN } = process.env

export const ConversationsContext = createContext({ sites: [], companies: [] })

export const CORE_CONVERSATION_FIELDS = gql`
  fragment CoreConversationFields on Conversation {
    id
    subject
    threadId
    updatedAt
  }
`

export const GET_CONVERSATION_ADMIN = gql`
  ${CORE_CONVERSATION_FIELDS}
  query GET_CONVERSATION_ADMIN($id: ID!) {
    conversationAdmin(id: $id) {
      ...CoreConversationFields
      messages {
        id
        messageTimeStamp
        text
        html
        reply
        sender {
          email
          name
          friendlyIdentifier
        }
        to {
          email
          name
          friendlyIdentifier
        }
        cc {
          email
          name
          friendlyIdentifier
        }
        attachments {
          filename
          url
        }
      }
    }
  }
`

const PRINCIPLE_QUERY = gql`
  query PRINCIPLE_QUERY(
    $companiesFilter: JSON!
    $sitesFilter: JSON!
    $usersFilter: JSON!
    $landownersFilter: JSON!
  ) {
    companies: findCompaniesAdmin(filter: $companiesFilter) {
      id
      name
    }
    sites: findSitesAdmin(filter: $sitesFilter) {
      id
      name
      landowners {
        all {
          id
          name
          email
        }
        anchor {
          id
          name
          email
        }
      }
    }
    users: findUsersAdmin(filter: $usersFilter) {
      id
      firstName
      lastName
      email
      company {
        id
      }
    }
    landowners: findLandownersAdmin(filter: $landownersFilter) {
      id
      name
      email
    }
  }
`

const FIND_CONVERSATIONS_ADMIN = gql`
  ${CONVERSATION_FIELDS}
  query FIND_CONVERSATIONS_ADMIN($filter: JSON!) {
    conversations: findConversationsAdmin(filter: $filter) {
      ...ConversationFields
      messages {
        text
      }
    }
  }
`

export const Stack = styled.div`
  display: flex;
  align-self: stretch;
  ${props =>
    props.direction === 'horizontal'
      ? css`
          flex-direction: row;
          align-items: center;
        `
      : css`
          flex-direction: column;
          flex: 1 1 auto;
        `}
  row-gap: ${({ gap }) => (gap || 0) * 0.25}rem !important;
  column-gap: ${({ gap }) => (gap || 0) * 0.25}rem !important;
`

export const MainContainer = styled.div.attrs({
  className: 'ql-container ql-snow border-0 h-100',
})`
  overflow: hidden !important;
`

export const NotesContainer = styled.div.attrs({
  className: 'ql-editor rounded p-0 h-100',
})`
  overflow: hidden !important;
`

const copyToClipboard = text => navigator.clipboard.writeText(text)

function MessageTile({ message }) {
  const [showOriginalMessage, setShowOriginalMessage] = useState(false)

  const handleShowOriginalMessage = () =>
    setShowOriginalMessage(!showOriginalMessage)

  return (
    <div>
      <Stack direction="horizontal" className="justify-content-between">
        <Stack direction="horizontal">
          <Text size={12} color={PRIMARY_1}>
            <b>{message.sender.name || message.sender.email.split('@')[0]}</b>
          </Text>
          <OverlayTrigger
            placement="top"
            trigger="click"
            overlay={
              <Popover>
                <Popover.Content>
                  <table id="conv-table">
                    <tr>
                      <td>
                        <Text size={12}>From:</Text>
                      </td>
                      <td>
                        <Stack direction="horizontal" gap={1}>
                          <Text size={12} color={`black`} weight="bold">
                            {message.sender.email}
                          </Text>
                          <span
                            className="material-icons icon-12"
                            role="button"
                            onClick={() =>
                              copyToClipboard(message.sender.email)
                            }
                          >
                            content_copy
                          </span>
                        </Stack>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Text size={12}>To:</Text>
                      </td>
                      <td>
                        <Stack>
                          {(message.to || []).map((recipient, i) => (
                            <Stack
                              key={String(i)}
                              direction="horizontal"
                              gap={1}
                            >
                              <Text color={'black'} size={12} className="lh-sm">
                                {recipient.email}
                              </Text>
                              <span
                                className="material-icons icon-12"
                                role="button"
                                onClick={() => copyToClipboard(recipient.email)}
                              >
                                content_copy
                              </span>
                            </Stack>
                          ))}
                        </Stack>
                      </td>
                    </tr>
                    {!!message.cc?.length && (
                      <tr>
                        <td>
                          <Text size={12}>Cc:</Text>
                        </td>
                        <td>
                          <Stack>
                            {(message.cc || []).map((recipient, i) => (
                              <Stack
                                key={String(i)}
                                direction="horizontal"
                                gap={1}
                              >
                                <Text
                                  color={'black'}
                                  size={12}
                                  className="lh-sm"
                                >
                                  {recipient.email}
                                </Text>
                                <span
                                  className="material-icons icon-12"
                                  role="button"
                                  onClick={() =>
                                    copyToClipboard(recipient.email)
                                  }
                                >
                                  content_copy
                                </span>
                              </Stack>
                            ))}
                          </Stack>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <Text size={12}>Date:</Text>
                      </td>
                      <td>
                        <Text size={12} color={`black`}>
                          {new Date(message.messageTimeStamp).toLocaleString(
                            'en-US',
                            {
                              timeStyle: 'short',
                              dateStyle: 'short',
                            }
                          )}
                        </Text>
                      </td>
                    </tr>
                  </table>
                </Popover.Content>
              </Popover>
            }
            rootClose
          >
            <span className="material-icons" role="button">
              arrow_drop_down
            </span>
          </OverlayTrigger>
          <Text
            role="button"
            weight="bold"
            size={10}
            onClick={handleShowOriginalMessage}
          >
            {showOriginalMessage ? `See Less` : `See More`}
          </Text>
        </Stack>
        <Text size={12}>
          {new Date(message.messageTimeStamp).toLocaleString('en-US', {
            timeStyle: 'short',
            dateStyle: 'short',
          })}
        </Text>
      </Stack>
      <div
        dangerouslySetInnerHTML={{
          __html: (showOriginalMessage ? message.html : message.reply) || '',
        }}
      />
    </div>
  )
}

function Threads({ filter }) {
  const { sites, companies } = useContext(ConversationsContext)
  const [selectedConversation, setSelectedConversation] = useState(null)

  const { loading, error, data } = useQuery(FIND_CONVERSATIONS_ADMIN, {
    variables: { filter },
  })

  useEffect(() => {
    if (data?.conversations?.length) {
      setSelectedConversation(data.conversations[0].id)
    }
  }, [data])

  if (loading) return <p>loading...</p>
  if (error) return <p>error...</p>

  const { conversations } = data

  if (!conversations.length)
    return (
      <Stack className="align-items-center justify-content-center h-100">
        <Text size={12} lines={Infinity} className="text-center">
          Uh-oh! It looks like our search party came back empty-handed. Maybe
          try a different path, or
          <br /> check if the search goblins ate your keywords!
        </Text>
      </Stack>
    )

  const populatedConversations = conversations.map(conversation => {
    const company = companies.find(company =>
      Object.is(company.id, conversation.company)
    )
    const site = sites.find(site => Object.is(site.id, conversation.site))

    return { ...conversation, company, site }
  })

  const clickedConversation = conversations.find(conversation =>
    Object.is(conversation.id, selectedConversation)
  )

  return (
    <BootstrapCard>
      <Row className="m-0" style={{ height: 'calc(100vh - 314px)' }}>
        <Col xs={3} className="p-1 border-right overflow-auto h-100">
          {populatedConversations.map(conversation => (
            <div
              role="button"
              onClick={() => setSelectedConversation(conversation.id)}
              className="p-1 rounded mb-1"
              style={{
                borderLeft: Object.is(conversation.id, selectedConversation)
                  ? `4px solid ${PRIMARY_2}`
                  : `4px solid ${NEUTRAL_2}`,
                background: NEUTRAL_2,
              }}
            >
              <div className="d-flex justify-content-between">
                <Text size={12} className="lh-sm" lines={Infinity}>
                  {conversation.company?.name} | {conversation.site?.name}
                  <br />
                  <b>
                    {conversation.messages.length ? (
                      conversation.subject
                    ) : (
                      <span style={{ color: 'red' }}>Draft:</span>
                    )}
                  </b>
                </Text>
                <Text size={10} className="lh-sm" style={{ marginTop: 2 }}>
                  {new Date(conversation.updatedAt).toLocaleString('en-US', {
                    timeStyle: 'short',
                    dateStyle: 'short',
                  })}
                </Text>
              </div>
            </div>
          ))}
        </Col>
        <Col xs={9} className="overflow-auto h-100 p-0">
          {clickedConversation && (
            <Messages conversationId={clickedConversation.id} />
          )}
        </Col>
      </Row>
    </BootstrapCard>
  )
}

function Messages({ conversationId }) {
  const { loading, error, data } = useQuery(GET_CONVERSATION_ADMIN, {
    variables: { id: conversationId },
  })

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { conversationAdmin } = data

  return (
    <div>
      <div className="p-2 border-bottom d-flex align-items-center justify-content-between">
        <Text color={PRIMARY_1} weight="bold" size={14}>
          {conversationAdmin.messages?.length
            ? conversationAdmin.subject
            : 'Draft:'}
        </Text>
        <Stack direction="horizontal" gap={1}>
          <Text size={12} color={`black`} weight="bold">
            {`${conversationAdmin.threadId}@${REACT_APP_INBOUND_EMAIL_SUBDOMAIN}.hstpowers.com`}
          </Text>
          <span
            className="material-icons icon-12"
            role="button"
            onClick={() =>
              copyToClipboard(
                `${conversationAdmin.threadId}@${REACT_APP_INBOUND_EMAIL_SUBDOMAIN}.hstpowers.com`
              )
            }
          >
            content_copy
          </span>
        </Stack>
      </div>
      {conversationAdmin.messages.map(message => (
        <div key={message.id} className="p-2 border rounded m-1">
          <MessageTile message={message} />
        </div>
      ))}
    </div>
  )
}

function SiteConversations() {
  const [filter, setFilter] = useState({
    sites: [],
    companies: [],
    sort: { updatedAt: -1 },
  })

  const handleFilterChange = data => setFilter({ ...filter, ...data })

  return (
    <React.Fragment>
      <ConversationFilter onChange={handleFilterChange} />
      <Threads filter={filter} />
    </React.Fragment>
  )
}

function Conversations() {
  const { loading, error, data } = useQuery(PRINCIPLE_QUERY, {
    variables: {
      companiesFilter: {},
      sitesFilter: { isPublished: true },
      usersFilter: {},
      landownersFilter: {},
    },
  })

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { sites, companies, users, landowners } = data

  return (
    <MainContainer>
      <NotesContainer>
        <Card padding={16}>
          <ConversationsContext.Provider
            value={{ sites, companies, users, landowners }}
          >
            <Stack direction="horizontal" className="justify-content-between">
              <Text size={24} weight="bold" color={PRIMARY_1}>
                Conversations
              </Text>
              <div className="d-flex" style={{ gap: 8 }}>
                <CreateDraft />
                <CreateConversation />
              </div>
            </Stack>
            <hr className="mt-1 mb-2" />
            <SiteConversations />
          </ConversationsContext.Provider>
        </Card>
      </NotesContainer>
    </MainContainer>
  )
}

export default Conversations
