import { useGetEmailDomains } from './hooks'
import { Badge } from 'react-bootstrap'
import Loader from '../Loader'
import NotFound from '../Error'
import { Text } from '../Themed'
import { PRIMARY_ALT } from '../../constants/colors'
import { BADGE_MAP } from './constants'
import DeleteDomain from './DeleteDomain'
import UpdateDomain from './UpdateDomain'

function Domains() {
  const { loading, error, data } = useGetEmailDomains()

  if (loading) return <Loader />
  if (error) return <p>error</p>
  if (!data?.domains?.length)
    return <NotFound subtitle="No email domains found!" />

  return (
    <div
      className="overflow-auto pl-4 pr-4"
      style={{ height: 'calc(100% - 100px)' }}
    >
      {data.domains.map(emailDomain => (
        <div className="border-bottom pb-1 pt-1" key={emailDomain.id}>
          <Text color={PRIMARY_ALT}>
            @{emailDomain.domain}{' '}
            <Badge variant={BADGE_MAP[emailDomain.status].variant}>
              {BADGE_MAP[emailDomain.status].label}
            </Badge>
          </Text>
          <div className="d-flex align-items-center">
            <span className="text-muted" style={{ fontSize: 12 }}>
              {emailDomain.organization} |
            </span>
            <UpdateDomain domain={emailDomain} />
            <DeleteDomain domain={emailDomain} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Domains
