import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { Stack } from '..'
import Select from 'react-select'
import { Text } from '../../Themed'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Modal } from 'react-bootstrap'
import { ConversationsContext } from '..'

export const CONVERSATION_FIELDS = gql`
  fragment ConversationFields on Conversation {
    id
    company
    site
    subject
    updatedAt
  }
`

const CREATE_DRAFT_ADMIN = gql`
  ${CONVERSATION_FIELDS}
  mutation CREATE_DRAFT_ADMIN($conversation: ConversationInput!) {
    createDraftAdmin(conversation: $conversation) {
      ...ConversationFields
    }
  }
`

const createDraftSchema = Yup.object({
  company: Yup.string().required('This is required!'),
  site: Yup.string().required('This is required!'),
})

function CreateDraftForm({ onSubmit, formRef }) {
  const { companies, sites } = useContext(ConversationsContext)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createDraftSchema),
  })

  const watchedSite = watch('site')

  useEffect(() => {
    if (watchedSite) {
      const val = (
        sites.find(s => Object.is(s.id, watchedSite))?.landowners?.anchor || []
      ).map(({ id }) => id)

      setValue('to', val)
    }
  }, [watchedSite, sites, setValue])

  const companyOptions = companies.map(({ id, name }) => ({
    value: id,
    label: name,
  }))
  const siteOptions = sites.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  return (
    <Form
      noValidate
      onSubmit={e => {
        //To prevent the event to propagate to the conversation selector form.
        e.stopPropagation()
        return handleSubmit(onSubmit)(e)
      }}
      ref={formRef}
    >
      <Form.Group className="m-0 pr-1" controlId="company">
        <Form.Label className="text-muted">Company</Form.Label>
        <Controller
          render={({ field: { onChange } }) => (
            <Select
              options={companyOptions}
              onChange={option => onChange(option.value)}
            />
          )}
          name="company"
          control={control}
        />
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors.company?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="m-0 pl-1" controlId="site">
        <Form.Label className="text-muted">Site</Form.Label>
        <Controller
          render={({ field: { onChange } }) => (
            <Select
              options={siteOptions}
              onChange={option => onChange(option.value)}
            />
          )}
          name="site"
          control={control}
        />
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors.site?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  )
}

function CreateDraft() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [createDraftAdmin, { loading }] = useMutation(CREATE_DRAFT_ADMIN, {
    onCompleted: handleClose,
  })

  const formRef = useRef(null)

  const handleThreadCreate = data => {
    const { company, site } = data

    createDraftAdmin({
      variables: {
        conversation: {
          company,
          site,
        },
      },

      update(cache, { data: { createDraftAdmin } }) {
        cache.modify({
          fields: {
            findConversationsAdmin(existingConversations = []) {
              const newConversationRef = cache.writeFragment({
                data: createDraftAdmin,
                fragment: gql`
                  fragment NewConversation on Conversation {
                    id
                    company
                    site
                    subject
                    updatedAt
                  }
                `,
              })
              return [...existingConversations, newConversationRef]
            },
          },
        })
      },
    })
  }

  const handleSubmit = (
    () => () =>
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
  )(formRef)

  return (
    <React.Fragment>
      <Button
        size="sm"
        className="btn-mid"
        style={{ width: 'fit-content' }}
        onClick={handleShow}
      >
        <Stack direction="horizontal" gap={1}>
          <span className="material-icons icon-14">forum</span>
          <span>Create Draft</span>
        </Stack>
      </Button>
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Text size={24} weight="bold">
            Create
          </Text>
        </Modal.Header>
        <Modal.Body>
          <CreateDraftForm formRef={formRef} onSubmit={handleThreadCreate} />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleSubmit}>
            Save Draft
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default CreateDraft
