import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'

import App from './components/App'
import client from './apollo-client'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'quill-image-uploader/dist/quill.imageUploader.min.css'
import 'react-quill/dist/quill.snow.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)
