import { useEffect, useState, useRef } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { DomainForm } from './forms'
import { useCreateEmailDomain } from './hooks'

function AddDomain(props) {
  const { className = '' } = props
  const formRef = useRef(null)
  const [createDomain, { loading, data }] = useCreateEmailDomain()
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = (
    () => () =>
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
  )(formRef)

  useEffect(() => {
    if (data?.createDomain?.id) handleClose()
  }, [data])

  return (
    <>
      <Button variant="primary" className={className} onClick={handleShow}>
        Add Domain
      </Button>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header>
          <Modal.Title>Add Email Domain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DomainForm formRef={formRef} onFormSubmit={createDomain} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
            className="d-flex align-items-center justify-content-center"
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
              />
            )}
            <span>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddDomain
