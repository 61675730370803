import React from 'react'
import { Redirect } from 'react-router-dom'

import { SIGN_IN } from '../../constants/routes'
import { useQuery } from '@apollo/client'
import { GET_ME } from './queries'

export const withAuthorization = conditionFn => Component => props => {
  const { data, networkStatus } = useQuery(GET_ME, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })

  if (networkStatus < 7) return null

  if (!conditionFn(data)) {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
  }

  return (
    <>
      {conditionFn(data) ? <Component {...props} /> : <Redirect to={SIGN_IN} />}
    </>
  )
}

const withSession = Component => props => {
  const { loading, data, refetch } = useQuery(GET_ME, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })

  return (
    <Component session={data} refetch={refetch} loading={loading} {...props} />
  )
}

export default withSession
