import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
//@ts-expect-error: Will fix later. Though I don't think it will be fixed.
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const removeTypenameLink = removeTypenameFromVariables()

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token')
    const rToken = localStorage.getItem('refreshToken')

    if (token) {
      headers = { ...headers, 'x-token': token }
    }

    if (rToken) {
      headers = { ...headers, 'r-token': rToken }
    }

    return { headers }
  })

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }
  if (networkError) console.warn(`[Network error]: ${networkError}`)
})

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_BACKEND_SERVER}/graphql`,
  headers: {
    'apollo-require-preflight': true,
  },
})

export const client = new ApolloClient({
  name: 'admin-web-client',
  cache: new InMemoryCache(),
  link: ApolloLink.from([removeTypenameLink, errorLink, authLink, uploadLink]),
})

export default client
