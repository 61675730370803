import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

function DateRange(props) {
  const {
    range: { start, end },
    onRangeSelect,
  } = props
  const handleCallback = (start, end) => {
    onRangeSelect({ start, end })
  }
  const label =
    start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')

  return (
    <DateRangePicker
      initialSettings={{
        startDate: start.toDate(),
        endDate: end.toDate(),
        ranges: {
          Today: [moment().toDate(), moment().toDate()],
          Yesterday: [
            moment().subtract(1, 'days').toDate(),
            moment().subtract(1, 'days').toDate(),
          ],
          'Last 7 Days': [
            moment().subtract(6, 'days').toDate(),
            moment().toDate(),
          ],
          'Last 30 Days': [
            moment().subtract(29, 'days').toDate(),
            moment().toDate(),
          ],
          'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
          ],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
          ],
        },
      }}
      onCallback={handleCallback}
    >
      <div
        style={{
          background: '#fff',
          cursor: 'pointer',
          padding: '10px 10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          display: 'inline-block',
        }}
      >
        <i className="fa fa-calendar"></i>&nbsp;
        <span>{label}</span> <i className="fa fa-caret-down"></i>
      </div>
    </DateRangePicker>
  )
}

export function SingleDatePicker(props) {
  const { onChange, value } = props

  return (
    <DateRangePicker
      initialSettings={{
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        drops: 'auto',
        startDate: moment(value).toDate(),
        minYear: 2020,
      }}
      onCallback={onChange}
    >
      <input
        style={{ height: '3rem' }}
        defaultValue={moment(value).format('MM/DD/YYYY')}
        className="form-control col-8"
      />
    </DateRangePicker>
  )
}

export default DateRange
