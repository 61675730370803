import React, { useState } from 'react'
import { gql, useMutation, useQuery, useApolloClient } from '@apollo/client'
import { Button, Modal, Form } from 'react-bootstrap'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import ImageUploader from 'quill-image-uploader'

Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageUploader', ImageUploader)

export const UPLOAD_NOTES_IMAGE = gql`
  mutation UPLOAD_NOTES_IMAGE($file: Upload!) {
    url: uploadNotesImage(file: $file)
  }
`

const siteNotesSchema = Yup.object({
  html: Yup.string(),
})

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  ['link', 'image', 'video', 'formula'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['clean'], // remove formatting button
]

const modules = {
  toolbar: toolbarOptions,
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
}

const FIND_SITE_NOTE = gql`
  query FIND_SITE_NOTE($filter: JSON!) {
    siteNotes: findSiteNotes(filter: $filter) {
      id
      html
    }
  }
`

const UPDATE_SITE_NOTE = gql`
  mutation UPDATE_SITE_NOTE($id: ID, $siteNote: SiteNoteInput!) {
    updateSiteNote(id: $id, siteNote: $siteNote) {
      id
      html
    }
  }
`

const CREATE_SITE_NOTE = gql`
  mutation CREATE_SITE_NOTE($siteNote: SiteNoteInput!) {
    createSiteNote(siteNote: $siteNote) {
      id
      html
    }
  }
`

function SiteNoteForm({ html, onCancel, id, siteId, refetch }) {
  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { html },
    resolver: yupResolver(siteNotesSchema),
  })

  const client = useApolloClient()

  const onCompleted = () => {
    refetch()
    onCancel()
  }

  const [updateSiteNote, { loading: updatingSiteNote }] = useMutation(
    UPDATE_SITE_NOTE,
    { onCompleted }
  )
  const [createSiteNote, { loading: creatingSiteNote }] = useMutation(
    CREATE_SITE_NOTE,
    { onCompleted }
  )

  const onSubmit = data => {
    const siteNote = { site: siteId, ...data }

    if (id) {
      updateSiteNote({ variables: { id, siteNote } })
    } else {
      createSiteNote({ variables: { siteNote } })
    }
  }

  const quillModules = {
    ...modules,
    imageUploader: {
      upload: async file => {
        const { data } = await client.mutate({
          mutation: UPLOAD_NOTES_IMAGE,
          variables: { file },
        })

        return data.url
      },
    },
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <ReactQuill
            theme="snow"
            modules={quillModules}
            value={value}
            onChange={onChange}
          />
        )}
        name="html"
        control={control}
      />
      <div className="d-flex justify-content-end pt-2">
        <Button
          variant="secondary"
          disabled={creatingSiteNote || updatingSiteNote}
          className="mr-2"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </div>
    </Form>
  )
}

function EditSiteNote({ id, onCancel }) {
  const { loading, error, data, refetch } = useQuery(FIND_SITE_NOTE, {
    variables: { filter: { site: id } },
  })

  if (loading) return <p>loading...</p>
  if (error) return <p>error...</p>

  return (
    <SiteNoteForm
      html={data.siteNotes[0]?.html || ''}
      onCancel={onCancel}
      id={data.siteNotes[0]?.id}
      siteId={id}
      refetch={refetch}
    />
  )
}

function SiteNotes({ id }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <React.Fragment>
      <Button variant="outline-primary" size="sm" onClick={handleShow}>
        Details
      </Button>
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Body>
          <EditSiteNote id={id} onCancel={handleClose} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default SiteNotes
