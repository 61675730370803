import React, { useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from 'chart.js'
import { Button, Spinner } from 'react-bootstrap'
import moment from 'moment'
import Select from 'react-select'
import { Line } from 'react-chartjs-2'
import { Parser } from 'json2csv'
import 'chartjs-adapter-moment'

import { Card } from '../Themed'
import { withAuthorization } from '../Session'
import DateRange from '../DateRange'
import Loader from '../Loader'
import { DROPDOWN_STYLES } from '../../constants/styles'

import * as hooks from './hooks'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
)

const options = {
  maintainAspectRatio: false,
  layout: {
    padding: 10,
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
      },
    },
  },
}

function LineChart(props) {
  const { users, projects, newProjects, submissions, capacity } = props

  const metricArray = [users, projects, newProjects, submissions, capacity]
  let maxMetric = users
  for (let i = 1; i < metricArray.length; i++) {
    if (metricArray[i]?.length > maxMetric?.length) maxMetric = metricArray[i]
  }

  const data = {
    labels: maxMetric?.map(({ x }) => x),
    datasets: [
      {
        label: 'Active Users',
        data: users,
        parsing: {
          yAxisKey: 'y',
        },
        fill: false,
        backgroundColor: 'red',
        borderColor: 'red',
      },
      {
        label: 'Active Projects',
        data: projects,
        parsing: {
          yAxisKey: 'y',
        },
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
      },
      {
        label: 'New Projects',
        data: newProjects,
        parsing: {
          yAxisKey: 'y',
        },
        fill: false,
        backgroundColor: 'blue',
        borderColor: 'blue',
      },
      {
        label: 'Simulations',
        data: submissions,
        parsing: {
          yAxisKey: 'y',
        },
        fill: false,
        backgroundColor: 'cyan',
        borderColor: 'cyan',
      },
      {
        label: 'Active GW Capacity Modeled',
        data: capacity,
        parsing: {
          yAxisKey: 'y',
        },
        fill: false,
        backgroundColor: 'brown',
        borderColor: 'brown',
      },
    ],
  }

  return <Line data={data} options={options} />
}

function UsageChart(props) {
  const { loading, data } = props
  return (
    <div className="h-100 pb-4">
      {loading && <Loader />}
      <LineChart
        users={data?.activeUsers}
        projects={data?.activeProjects}
        newProjects={data?.newProjects}
        submissions={data?.submissions}
        capacity={data?.activeGWCapacity}
      />
    </div>
  )
}

function Usage() {
  const [selectedCompany, setSelectedCompany] = useState(undefined)
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
  })

  const { loading, error, data } = hooks.useGetAllCompanies()
  const [usage, { loading: loadingUsageData, data: usageRes }] =
    hooks.useGetUsage()
  const {
    loading: loadingStats,
    data: stats,
    error: statsError,
  } = hooks.useGetUsageStats({
    start: dateRange.start.utc().toDate().toUTCString(),
    end: dateRange.end.utc().toDate().toUTCString(),
    company: selectedCompany,
  })

  useEffect(() => {
    if (usageRes?.usage?.length) {
      const fields = [
        '_id',
        'companyName',
        'name',
        'createdAt',
        'lastAcSystemSizeInKw',
        'successfulOptimizations',
        'optimizerCount',
        'buildableAreaInAcres',
      ]
      const opts = { fields }

      try {
        const parser = new Parser(opts)
        const csv = parser.parse(usageRes.usage)

        let pom = document.createElement('a')
        const csvContent = csv
        const blob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8;',
        })
        const url = URL.createObjectURL(blob)
        pom.href = url
        pom.setAttribute('download', 'usage.csv')
        pom.click()
      } catch (err) {
        console.error(err)
      }
    }
  }, [usageRes])

  if (loading) return <Loader />
  if (error || statsError) return <p>error...</p>

  const { companies } = data

  return (
    <Card padding={32}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="w-50">
          <Select
            className="mr-2 mt-2 w-50"
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            styles={DROPDOWN_STYLES}
            defaultValue={{ label: 'All Companies', value: undefined }}
            options={[
              { label: 'All Companies', value: undefined },
              ...companies.map(company => ({
                label: company.name,
                value: company.id,
              })),
            ]}
            onChange={({ value }) => setSelectedCompany(value)}
          />
        </div>
        <div>
          <DateRange
            onRangeSelect={({ start, end }) => setDateRange({ start, end })}
            range={dateRange}
          />
          <Button
            style={{ width: 180 }}
            variant="link"
            className="ml-2"
            disabled={loadingUsageData}
            onClick={() =>
              usage({
                start: dateRange.start.utc().toDate().toUTCString(),
                end: dateRange.end.utc().toDate().toUTCString(),
                company: selectedCompany,
              })
            }
          >
            <div className="d-flex align-items-center justify-content-center">
              {loadingUsageData && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              <span>{loadingUsageData ? 'Generating...' : 'Generate CSV'}</span>
            </div>
          </Button>
        </div>
      </div>
      <UsageChart loading={loadingStats} data={stats} />
    </Card>
  )
}

export default withAuthorization(session => session && session.me)(Usage)
