import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Form } from 'react-bootstrap'

import Loader from '../Loader'
import { Card, CardContent, Title } from '../Themed'
import { withAuthorization } from '../Session'
import ToastNotification from '../Notification'

import { CompanyUpdateForm } from './forms'

import * as hooks from './hooks'

function CompanySettings(props) {
  const { company } = props
  const [show, setShow] = useState(false)

  const [updateCompanySettings, { loading, data }] =
    hooks.useUpdateCompanySettings()

  useEffect(() => {
    if (data?.updateCompany?.id) setShow(true)
  }, [data])

  return (
    <>
      <ToastNotification
        show={show}
        onSuspend={() => setShow(false)}
        headerText="SUCCESS"
        bodyText={
          <span>
            <b>{company.name} </b>settings updated successfully!
          </span>
        }
        type="primary"
      />
      <CompanyUpdateForm
        values={company?.settings || {}}
        onFormSubmit={values =>
          updateCompanySettings(company.id, {
            settings: values,
            name: company.name,
          })
        }
        loading={loading}
      />
    </>
  )
}

function Companies() {
  const [selectedCompany, setSelectedCompany] = useState(undefined)

  const { loading, error, data } = hooks.useGetAllCompanies()

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { companies } = data

  return (
    <Card padding={32}>
      <CardContent>
        <Title>EDIT COMPANY SETTINGS:</Title>
        <div>
          <Form.Label className="text-muted">
            <b>Select Company</b>
          </Form.Label>
          <div className="d-flex align-items-center justify-center">
            <Select
              className="mr-2 mt-2 w-25"
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              // styles={DROPDOWN_STYLES}
              options={companies.map(company => ({
                label: company.name,
                value: company.id,
                ...company,
              }))}
              onChange={value => setSelectedCompany(value)}
            />
            {selectedCompany?.legal && (
              <a
                className="btn btn-outline-primary mt-2 btn-lg"
                href={selectedCompany.legal.msa}
                download
              >
                <div className="d-flex align-items-center justify-center">
                  <span className="material-icons mr-2 icon-primary">
                    cloud_download
                  </span>
                  <span>Download MSA</span>
                </div>
              </a>
            )}
          </div>
        </div>
        {selectedCompany && (
          <div className="w-100 mt-4">
            <CompanySettings company={selectedCompany} />
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default withAuthorization(session => session && session.me)(Companies)
